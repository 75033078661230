import { range } from 'ramda'

export const groupByWithFn = <T, U>(list: T[], groupFn: (_item: T) => U) => {
  const map = new Map<U, T[]>()
  for (const item of list) {
    const key = groupFn(item)
    const collection = map.get(key)

    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  }

  return map
}

export interface MakeMultiTreeArgs<NodeType, KeyType> {
  nodes: NodeType[]
  idFn: (_node: NodeType) => KeyType
  parentIdFn: (_node: NodeType) => KeyType
}

export type Node<NodeType> = NodeType & {
  children?: Node<NodeType>[]
}

type NodeWithId<NodeType, KeyType> = Node<NodeType> & { id?: KeyType }

export const findNodeInMultiTree = <NodeType, KeyType>(
  multiTree: NodeWithId<NodeType, KeyType>[],
  id: KeyType,
): NodeWithId<NodeType, KeyType> => {
  const searchTree = (tree: NodeWithId<NodeType, KeyType>[]) => {
    for (const node of tree) {
      if (node.id === id) {
        return node
      }
      if (node.children) {
        const foundNode = searchTree(node.children)
        if (foundNode) {
          return foundNode
        }
      }
    }
  }

  return searchTree(multiTree)
}

export const makeMultiTree = <NodeType, KeyType>({
  nodes,
  idFn,
  parentIdFn,
}: MakeMultiTreeArgs<NodeType, KeyType>) => {
  const items = nodes.map((item) => ({
    ...item,
    parent: null as NodeType,
    children: [] as unknown as NodeType[],
  }))
  const groupedByParent = groupByWithFn(items, (kbItem) => parentIdFn(kbItem))
  const all = new Map(items.map((kbItem) => [idFn(kbItem), kbItem]))

  for (const [parentId, children] of groupedByParent) {
    if (parentId === null) {
      continue
    }

    const parent = all.get(parentId)
    if (parent) {
      for (const child of children) {
        child.parent = parent
      }
      parent.children = children
    }
  }

  const rootNodes = groupedByParent.get(null) || []
  return rootNodes
}

export const getDepth = <NodeType>(args: {
  node: NodeType
  allNodes: NodeType[]
  getId: (node: NodeType) => number
  getParentId: (node: NodeType) => number
  maxDepth: number
  currentDepth?: number
}) => {
  const {
    node,
    allNodes,
    getId,
    getParentId,
    maxDepth,
    currentDepth = 0,
  } = args

  if (currentDepth > maxDepth) {
    return currentDepth
  }

  const parentId = getParentId(node)
  if (parentId === null) {
    return currentDepth
  }

  const parent = allNodes.find((node) => getId(node) === parentId)
  if (!parent) {
    return currentDepth
  }

  return getDepth({
    node: parent,
    allNodes,
    getId,
    getParentId,
    maxDepth,
    currentDepth: currentDepth + 1,
  })
}

export const getMultiTreeNodeDepth = <T extends { parent?: T }>(
  node: T,
): number => {
  if (!node) {
    return 0
  }
  let depth = 0
  let currentNode = node

  while (currentNode.parent) {
    depth++
    currentNode = currentNode.parent
  }

  return depth
}

type FlatMapMergeFn<NodeType, U> = undefined | ((_node: Node<NodeType>) => U)

export const flatMapTree =
  <NodeType, U>(mergeFn?: FlatMapMergeFn<NodeType, U>) =>
  (parent: Node<NodeType>) => {
    const children = parent.children?.flatMap(flatMapTree(mergeFn)) ?? []

    // eslint-disable-next-line no-unused-vars
    const { children: _omitChildren, ...parentWithoutChildren } = parent

    const parentResult = parentWithoutChildren as Node<NodeType>

    const newFields = mergeFn?.(parentResult)
    const result = [
      { ...parentResult, ...newFields },
      ...children,
    ] as (Node<NodeType> & U)[]
    return result
  }

export const flatMapMultiTree = <NodeType, U>(
  parents: Node<NodeType>[],
  mergeFn?: FlatMapMergeFn<NodeType, U>,
) => {
  const result = parents.flatMap(flatMapTree(mergeFn))

  return result
}

export type TreeFn<NodeType> = (_node: Node<NodeType>) => boolean

export const trimTree =
  <NodeType>({ trimFn }: { trimFn: TreeFn<NodeType> }) =>
  (parent: Node<NodeType>): Node<NodeType> => {
    if (!trimFn) {
      throw new Error('trimFn is required')
    }

    const children = parent.children.map(trimTree({ trimFn })).filter(Boolean)

    return trimFn(parent) ? null : { ...parent, children }
  }

export const trimMultiTree = <NodeType>({
  parents,
  trimFn,
}: {
  parents: NodeType[]
  trimFn: TreeFn<NodeType>
}): Node<NodeType>[] => {
  return parents.map(trimTree({ trimFn })).filter(Boolean)
}

export const trimTreeChildren = <NodeType>(
  parent: Node<NodeType>,
  trimFn: TreeFn<NodeType>,
) => {
  if (!trimFn) {
    throw new Error('trimFn is required')
  }

  const children = parent.children
    ?.map((child) => !trimFn(child) && trimTreeChildren(child, trimFn))
    .filter(Boolean)

  const { children: _omitChildren, ...parentWithoutChildren } = parent

  return children
    ? { ...parentWithoutChildren, children }
    : parentWithoutChildren
}

export const trimMultiTreeChildren = <NodeType>(
  parents: Node<NodeType>[],
  trimFn: TreeFn<NodeType>,
) => {
  return parents.map((parent) => trimTreeChildren(parent, trimFn))
}

/**
 * Compare the elements of two arrays.
 * @returns true if both contain the same elements (any order), false otherwise.
 */
export const arrayEquals = <T>(a: T[], b: T[]): boolean => {
  if (a === b) {
    return true
  }
  if (a === null || b === null) {
    return false
  }
  if (!Array.isArray(a) || !Array.isArray(b)) {
    return false
  }
  if (a.length !== b.length) {
    return false
  }
  if (a.length === 0 && b.length === 0) {
    return true
  }

  const aSorted = [...a].sort()
  const bSorted = [...b].sort()

  return aSorted.every((value, index) => value === bSorted[index])
}

export interface Ancestors<T> {
  previous: T
  list: T[]
}
const _findAncestors = <T extends { id: U; parentId?: U }, U>(
  items: T[],
  ancestors: Ancestors<T>,
): T[] => {
  if (ancestors?.previous?.parentId) {
    const parent = items.find(
      (item) => item?.id === ancestors.previous.parentId,
    )

    return _findAncestors(items, {
      previous: parent,
      list: [...ancestors.list, parent],
    })
  } else {
    return ancestors.list.reverse()
  }
}
export const findAncestors = <T extends { id: U; parentId?: U }, U>(
  start: T,
  items: T[],
  includeStart?: boolean,
): T[] => {
  const ancestors = _findAncestors(items, { previous: start, list: [] })

  // If we want to include the start item.. i.e the item we are looking at.
  if (includeStart) ancestors.push(start)

  return ancestors
}

export const splitIntoBatches = <T>(items: T[], batchSize: number): T[][] => {
  const numBatches = Math.ceil(items.length / batchSize)
  const batches = range(0, numBatches).map((i) =>
    items.slice(i * batchSize, (i + 1) * batchSize),
  )

  return batches
}
